<template>
  <div class="videos fill-height">
    <div
      class="window"
      :class="{ paused: IO.Paused }"
      @click="pauseplayAll"
    ></div>
    <transition name="fadebuffer">
      <div class="buffering" v-show="IO.Buffering"></div>
    </transition>
    <vimeo-player
      ref="Vid1"
      video-id="532258600"
      class="video-embed"
      v-show="Show.Vid1"
      :options="{ responsive: true, controls: false, autopause: false }"
      @timeupdate="Vid1TUpdate"
      @bufferstart="SetBuffering(true, 1)"
      @bufferend="SetBuffering(false, 1)"
      @pause="VideoPaused(1)"
    />
    <vimeo-player
      ref="Vid2"
      video-id="532258600"
      class="video-embed"
      v-show="Show.Vid2"
      :options="{ responsive: true, controls: false, autopause: false }"
      @timeupdate="Vid2TUpdate"
      @bufferstart="SetBuffering(true, 2)"
      @bufferend="SetBuffering(false, 2)"
      @pause="VideoPaused(2)"
    />
    <vimeo-player
      ref="Vid3"
      video-id="532258600"
      class="video-embed"
      v-show="Show.Vid3"
      :options="{ responsive: true, controls: false, autopause: false }"
      @timeupdate="Vid3TUpdate"
      @bufferstart="SetBuffering(true, 3)"
      @bufferend="SetBuffering(false, 3)"
      @pause="VideoPaused(3)"
    />
    <span
      class="captions"
      :class="{ enabled: Captions }"
      @click="toggleCaptions"
      >Captions</span
    >
    <!-- <div style="background: red;" @click="shiftPlayback(2)">
      optionTime: {{ optionTime }} Active Time: {{ this.ActiveTime }} Next
      Video: {{ this.nextVideo }} Meta:
      {{ this.allMeta[ActiveVideo] }}
      Op Appear: {{ meta.OpAppearSecs }}
    </div> -->
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      ActiveVideo: 1,
      ActivePlayer: 1,
      ActiveTime: 0,
      Setup: false,
      Show: {
        Vid1: true,
        Vid2: false,
        Vid3: false
      },
      IO: {
        Paused: false,
        Buffering: true
      },
      Captions: false
    };
  },
  props: [
    "Experience",
    "meta",
    "allMeta",
    "optionTime",
    "nextPlayer",
    "nextVideo"
  ],
  methods: {
    pauseplayAll() {
      if (!this.IO.Paused) {
        Promise.allSettled([
          this.$refs.Vid1.pause(),
          this.$refs.Vid2.pause(),
          this.$refs.Vid3.pause()
        ]).then(() => {
          this.IO.Paused = true;
        });
      } else {
        if (this.Show.Vid1) {
          this.$refs.Vid1.play().then(() => {
            this.IO.Paused = false;
          });
        }
        if (this.Show.Vid2) {
          this.$refs.Vid2.play().then(() => {
            this.IO.Paused = false;
          });
        }
        if (this.Show.Vid3) {
          this.$refs.Vid3.play().then(() => {
            this.IO.Paused = false;
          });
        }
      }
    },
    VideoPaused(player) {
      if (player == this.ActivePlayer) {
        this.IO.Paused = true;
      }
    },
    setTime(id, time) {
      if (id == 1) {
        this.$refs.Vid1.player.setCurrentTime(time).then(() => {
          this.$refs.Vid1.play().then(() => {
            this.$refs.Vid1.pause();
            console.log("SETTIME: Time set on P1 to " + time);
          });
        });
      }
      if (id == 2) {
        this.$refs.Vid2.player.setCurrentTime(time).then(() => {
          this.$refs.Vid2.play().then(() => {
            this.$refs.Vid2.pause();
            console.log("SETTIME: Time set on P2 to " + time);
          });
        });
      }
      if (id == 3) {
        this.$refs.Vid3.player.setCurrentTime(time).then(() => {
          this.$refs.Vid3.play().then(() => {
            this.$refs.Vid3.pause();
            console.log("SETTIME: Time set on P3 to " + time);
          });
        });
      }
    },
    SetBuffering(val, player) {
      if (player == this.ActivePlayer) {
        this.IO.Buffering = val;
      }
    },
    Vid1TUpdate(data) {
      if (this.ActivePlayer == 1) {
        this.ActiveTime = data.seconds;
      }
    },
    Vid2TUpdate(data) {
      if (this.ActivePlayer == 2) {
        this.ActiveTime = data.seconds;
      }
    },
    Vid3TUpdate(data) {
      if (this.ActivePlayer == 3) {
        this.ActiveTime = data.seconds;
      }
    },
    shiftPlayback(to) {
      this.$emit("UpdateId", this.nextVideo);
      if (to == 1) {
        this.$refs.Vid1.play().then(() => {
          setTimeout(() => {
            this.$refs.Vid2.pause();
            this.$refs.Vid3.pause();
          }, 500);
          setTimeout(() => {
            this.Show = { Vid1: true, Vid2: false, Vid3: false };
          }, 250);

          this.ActivePlayer = 1;
          console.log("Shifted Playback to: P1");
        });
      }
      if (to == 2) {
        this.$refs.Vid2.play().then(() => {
          setTimeout(() => {
            this.$refs.Vid1.pause();
            this.$refs.Vid3.pause();
          }, 500);
          setTimeout(() => {
            this.Show = { Vid1: false, Vid2: true, Vid3: false };
          }, 250);

          this.ActivePlayer = 2;
          console.log("Shifted Playback to: P2");
        });
      }
      if (to == 3) {
        this.$refs.Vid3.play().then(() => {
          setTimeout(() => {
            this.$refs.Vid1.pause();
            this.$refs.Vid2.pause();
          }, 500);
          this.Show = { Vid1: false, Vid2: false, Vid3: true };

          this.ActivePlayer = 3;
          console.log("Shifted Playback to: P3");
        });
      }
    },
    toggleCaptions() {
      if (this.Captions) {
        // Disable
        console.log("Disable captions");
        Promise.allSettled([
          this.$refs.Vid1.player.disableTextTrack(),
          this.$refs.Vid2.player.disableTextTrack(),
          this.$refs.Vid3.player.disableTextTrack()
        ]).then(() => {
          this.Captions = false;
          this.$store.commit("disablecc");
        });
      } else {
        console.log("Enable captions");
        Promise.allSettled([
          this.$refs.Vid1.player.enableTextTrack("en-GB"),
          this.$refs.Vid2.player.enableTextTrack("en-GB"),
          this.$refs.Vid3.player.enableTextTrack("en-GB")
        ]).then(() => {
          this.Captions = true;
          this.$store.commit("enablecc");
        });
      }
    }
  },
  watch: {
    Experience: {
      deep: true,
      handler(val) {
        //    Start the Show    ///   Watch and play Video1 if user starts the show
        if (val.Started) {
          this.$refs.Vid1.play();

          // Initial Setup
          if (!this.Setup) {
            let opA = this.allMeta[1].OpAId;
            let opB = this.allMeta[1].OpBId;
            let opAPlayer = this.allMeta[opA].Player;
            let opBPlayer = this.allMeta[opB].Player;
            let opASecs = this.allMeta[opA].Start;
            let opBSecs = this.allMeta[opB].Start;
            console.log(
              `AP:${opAPlayer}  ASec:${opASecs}  BP:${opBPlayer}  BSec:${opBSecs}`
            );
            // Update players
            this.setTime(opAPlayer, opASecs);
            this.setTime(opBPlayer, opBSecs);
            this.Setup = true;
          }
        }
      }
    },
    meta(val) {
      let id = val.Id;
      // Update players on change
      let opA = this.allMeta[id].OpAId;
      let opB = this.allMeta[id].OpBId;
      console.log("Meta has changed.");
      console.log("OpA: " + opA);
      console.log("OpB: " + opB);

      if (opA) {
        let opAPlayer = this.allMeta[opA].Player;
        let opASecs = this.allMeta[opA].Start;
        this.setTime(opAPlayer, opASecs);
        // Set default next - Option A
        this.$emit("selectOption", opA);
        console.log(
          "VideoHolder:meta watch updated A P" + opAPlayer + " to " + opASecs
        );
      }
      if (opB) {
        let opBPlayer = this.allMeta[opB].Player;
        let opBSecs = this.allMeta[opB].Start;
        this.setTime(opBPlayer, opBSecs);
        console.log(
          "VideoHolder:meta watch updated A P" + opBPlayer + " to " + opBSecs
        );
      }
    },
    //    Watch the current playing time and update overall watcher.
    ActiveTime(val) {
      this.$emit("UpdateTime", val);
    },
    optionTime(val) {
      if (val <= 0.5) {
        this.shiftPlayback(this.nextPlayer);
      }
      if (this.meta.Ending && val <= 0.5) {
        // At the end, push user to map
        this.$router.push({
          path: "/map",
          query: { d: this.$store.state.viewingdate }
        });
      }
    }
  },
  mounted() {
    // Testing!!
    if (this.$route.query.fast) {
      this.$refs.Vid1.player.setPlaybackRate(2);
      this.$refs.Vid2.player.setPlaybackRate(2);
      this.$refs.Vid3.player.setPlaybackRate(2);
    }
    // Testing!!

    if (!this.$store.state.captions) {
      Promise.allSettled([
        this.$refs.Vid1.player.disableTextTrack(),
        this.$refs.Vid2.player.disableTextTrack(),
        this.$refs.Vid3.player.disableTextTrack()
      ]).then(() => {
        console.log("Disabled default captions");
      });
    } else {
      Promise.allSettled([
        this.$refs.Vid1.player.enableTextTrack("en-GB"),
        this.$refs.Vid2.player.enableTextTrack("en-GB"),
        this.$refs.Vid3.player.enableTextTrack("en-GB")
      ]).then(() => {
        console.log("Enabled captions");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.fadebuffer-enter-active,
.fadebuffer-leave-active {
  transition: 0.7s;
}
.fadebuffer-enter, .fadebuffer-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.videos {
  box-sizing: border-box;
  position: relative;
  background: #000;

  &:hover .captions {
    opacity: 1;
  }

  .window {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: url(../assets/cursor-pause.png), auto;

    &.paused {
      cursor: url(../assets/cursor-hand.png), auto;
      background: url(../assets/window-paused.png) no-repeat;
      background-size: 100% 100%;
    }
  }

  .buffering {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: url(../assets/loading-video-v2.png) no-repeat;
    background-size: 100% 100%;
  }
}
.video-embed {
  width: 100%;
  background: #000;
}

// .captions {
//   display: block;
//   opacity: 0;
//   width: 60px;
//   height: 30px;
//   position: absolute;
//   bottom: 25px;
//   right: 15px;
//   z-index: 10;
//   text-indent: 100%;
//   white-space: nowrap;
//   overflow: hidden;
//   background: url(../assets/cc-icon.png) no-repeat;
//   background-size: contain;
//   cursor: pointer !important;
//   transition: 0.3s;

//   &:hover,
//   &.enabled {
//     filter: invert(100%);
//     -webkit-filter: invert(100%);
//   }
// }

.captions {
  display: block;
  opacity: 0;
  width: 155px;
  height: 92px;
  position: absolute;
  bottom: 25px;
  right: 15px;
  z-index: 10;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  background: url(../assets/enable-cc.png) no-repeat;
  background-size: contain;
  cursor: pointer !important;
  transition: 0.3s;

  &.enabled {
    background: url(../assets/disable-cc.png) no-repeat;
    background-size: contain;
  }
}
</style>
